import styles from './FAQ.module.css'

//img
import icon from '../../assets/icone_cycle.png'

const FAQ = () => {
  return (
    <div className={styles.faq}>
        <img src={icon} alt='Nossa logo' />
        <h1>Perguntas frequentes</h1>
        <details>
            <summary>Posso obter reembolso?</summary>
            <p>O reembolso tem o prazo de 7 dias úteis para ser realizado.</p>
        </details>  
        <details>
            <summary>O que faço após pagar meu produto?</summary>
            <p>Fale conosco pelo site ou abra um ticket em nosso discord com o comprovante para obter ajuda com o produto adquirido.</p>
        </details>
        <details>
            <summary>Qual o prazo de entrega do meu produto?</summary>
            <p>O prazo da entrega do seu produto varia dependendo do pedido desejado.</p>
        </details>
    </div>
  )
}

export default FAQ
