import styles from './Contact.module.css'
import emailjs from '@emailjs/browser'
import { useRef, useState, useEffect } from 'react'
import { useAuthentication } from '../../hooks/useAuthentication'

//img
import icon from '../../assets/icone_cycle.png'

const Contact = () => {
    const { auth } = useAuthentication();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [warningMessage, setWarningMessage] = useState('')

    const form = useRef();

    useEffect(() => {
        if (auth.currentUser) {
            setEmail(auth.currentUser.email);
        }
    }, [auth.currentUser]);

    const sendEmail = (e) => {
        e.preventDefault();

        if (name.trim() !== '' && email.trim() !== '' && message.trim() !== '') {
            emailjs
                .sendForm('service_ic4qri1', 'template_pej4h5i', form.current, 'U6dGtfqNmNYFqQ6HO')
                .then(() => {
                    setSuccessMessage("Sucesso! Aguarde em seu e-mail.")
                    setName('')
                    setMessage('')
                    setErrorMessage('')
                    setWarningMessage('')
                })
                .catch(error => {
                    setErrorMessage("Erro ao enviar e-mail: " + error.text)
                });
        } else {
            setWarningMessage("Complete todos os campos.")
            setErrorMessage('')
            setSuccessMessage('')
        }
    };

    return (
        <div className={styles.contact}>
            <img src={icon} alt='Nossa logo' />
            <h1>Compre agora</h1>
            {successMessage && <p style={{ color: 'green', textAlign:'center' }}>{successMessage}</p>}
            {errorMessage && <p style={{ color: 'red', textAlign:'center' }}>{errorMessage}</p>}
            {warningMessage && <p style={{ color: '#ffba0a', textAlign:'center' }}>{warningMessage}</p>}
            <form ref={form} onSubmit={sendEmail}>
                <input style={{
                }} placeholder='Seu melhor e-mail:' type="email" name="user_email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                <input placeholder='Digite seu nome:' type="text" name="user_name" value={name} onChange={(e) => setName(e.target.value)} />
                <textarea placeholder='Descreva seu desejo...' name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                <input type="submit" value="Enviar" />
            </form>
        </div>
    )
}

export default Contact