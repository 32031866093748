import './App.css'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useState, useEffect } from 'react';

import { onAuthStateChanged } from 'firebase/auth';

//Context
import { AuthProvider } from './context/AuthContext';

//hook
import { useAuthentication } from './hooks/useAuthentication';

//pages
import Home from './pages/Home/Home'
import NotFound from './pages/NotFound/NotFound'
import Contact from './pages/Contact/Contact'
import FAQ from './pages/FAQ/FAQ'
import Product from './pages/Product/Product'
import Login from './pages/Login/Login'
import Register from './pages/Register/Register'

//components
import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'

function App() {
  const [user, setUser] = useState(undefined)
  const {auth} = useAuthentication()

  const loadingUser = user === undefined

  useEffect(() => {

    onAuthStateChanged(auth, (user) => {
      setUser(user)
    })

  }, [auth]) 

  if(loadingUser) {
    return (
      <div style={{
        zIndex:'800',
        backgroundColor:'#0b0c0f',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        height:'100vh',
      }}>
        <div  className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) 
  }

  return (
    <div className='App'>
      <AuthProvider value={{ user }}>
      <NavBar/>
      <div className='container'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='*' element={<NotFound />}/>
            <Route path='/comprar' element={user ? <Contact /> : <Navigate to="/entrar"/>}/>
            <Route path='/perguntas' element={<FAQ/>}/>
            <Route path='/produtos/:productID' element={<Product/>}/>
            <Route path='/entrar' element={!user ? <Login/> : <Navigate to="/"/>}/>
            <Route path='/registro' element={!user ? <Register/> : <Navigate to="/"/>}/>
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
      </AuthProvider>
    </div>
  )
}

export default App
