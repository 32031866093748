import styles from './Register.module.css'

import { useEffect, useState } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavLink } from 'react-router-dom';

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const { createUser, error: authError, loading } = useAuthentication();

    const handleSubmit = async (e) =>{
        e.preventDefault()

        setError("")

        const user = {
            email,
            password,
        }

        if (password !== confirmPassword){
            toast.error("As senhas precisam ser iguais.")
            return
        }
        
        const res = await createUser(user)

        console.log(res)
    }

    useEffect(() => {
        if(authError) {
            setError(authError)
        }
    }, [authError])

    return (
        <div className={styles.register}>
            <h1>Registro</h1>
            <p>Crie uma conta para comprar</p>
            <form onSubmit={handleSubmit}>
                <input placeholder='Seu e-mail' type="email" name='email'  onChange={(e) => setEmail(e.target.value)} value={email}/>
                <input placeholder='Senha:' type="password"  name='password' onChange={(e) => setPassword(e.target.value)} value={password}/>
                <input placeholder='Confirme sua senha:' type='password' name='confirmPassword' onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword}/>
                {!loading && <button style={{marginTop:"0.4%", width:"100%"}}>Cadastre</button>}
                {loading && (
                    <button type="button" disabled>
                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status">Loading...</span>
                    </button>
                )}
                {error && <p className="error">{error}</p>}
                <p style={{
                    marginTop:'5%',
                    color:'#888888',
                }}>Ja tem uma conta? <a style={{
                    textDecoration:'none',
                }} href='/entrar'>Entrar</a></p>
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default Register
