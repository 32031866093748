import styles from './NotFound.module.css'
import { NavLink } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className={styles.nf}>
        <h1>404</h1>
        <h2>Página não encontrada</h2>
        <NavLink to={"/"}>
            <button>Volte para o início</button>
        </NavLink>
    </div>
  )
}

export default NotFound