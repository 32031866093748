import styles from './Footer.module.css'

//icon
import { PiDiscordLogo } from "react-icons/pi";
import { FaInstagram } from "react-icons/fa";

//img
import icon from '../../assets/icone_cycle.png'

const Footer = () => {
  const handleDiscordClick = () => {
    window.location.href = 'https://discord.gg/PjNgBp4gxT';
  };

  const handleInstagramClick = () => {
      window.location.href = 'https://www.instagram.com/cycleutilities/';
  };

  return (
    <footer>
      <img src={icon} alt='Nossa logo'/>
      <div className={styles.divContainerItens}>
      <div className={`${styles.btnGroup} ${"btn-group"}`}>
      <button className={`${styles.btnMenu} ${"btn btn-transparent dropdown-toggle"}`} data-bs-toggle="dropdown" aria-expanded="false">
      Opções
      </button>
      <ul className={`${styles.divContainerMenu} ${"dropdown-menu dropdown-menu-lg-end"}`}>
        <li className={styles.listItensContainerMenu}><a className={`${styles.itemsContainerMenu} ${"dropdown-item"}`} href="/">Início</a></li>
        <li className={styles.listItensContainerMenu}><a className={`${styles.itemsContainerMenu} ${"dropdown-item"}`} href="/comprar">Compre agora</a></li>
        <li className={styles.listItensContainerMenu}><a className={`${styles.itemsContainerMenu} ${"dropdown-item"}`} href="/perguntas">FAQ</a></li>
      </ul>
      </div>
        <PiDiscordLogo onClick={handleDiscordClick} className={styles.iconDisc} />
        <FaInstagram onClick={handleInstagramClick} className={styles.iconInsta}/>
      </div>
    </footer>
  )
}

export default Footer