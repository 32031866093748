import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDNRnsAYW6uMwVYHLSahE9P2AZL9qpJdvo",
  authDomain: "cycle-db-67c42.firebaseapp.com",
  projectId: "cycle-db-67c42",
  storageBucket: "cycle-db-67c42.appspot.com",
  messagingSenderId: "334268011980",
  appId: "1:334268011980:web:2f9e42b337323373acb969"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app)

export { db }