import styles from './Home.module.css'
import { useParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

//jsons
import jsonNotice from '../../notices.json'
import jsonProducts from '../../products.json'

//img
import icon from '../../assets/icone_cycle.png'

const Home = () => {
    const { id } = useParams();

    return (
        <div className={styles.home}>
            <ul className={styles.circles}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
            <div className={styles.divHome}>
                <img src={icon} alt='Nossa logo'/>
                <h1>Cycle Utilities</h1>
                <h2>Personalize sua presença online</h2>
                <p>Cansado de websites genéricos? A Cycle Utilities é a solução completa para você personalizar sua experiência a outro nível. Nossa plataforma inovadora oferece uma ótima experiência.</p>
                <div className={styles.divHomeButtons}>
                    <NavLink style={{
                        marginRight:'1%',
                    }} to={"/comprar"}>
                        <button className={styles.buttonBuyItem} style={{
                            border:'1px solid #ffba0a',
                        }}>Comprar</button>
                    </NavLink>
                    <NavLink style={{
                        marginLeft:'1%',
                    }} to={"/perguntas"}>
                        <button>FAQ</button>
                    </NavLink>
                </div>
            </div>
            <div className={styles.divProducts}>
                <h1>Produtos</h1>
                <div className={styles.containerProducts}>
                    {jsonProducts.products.map((item2) => (
                        <div key={item2.id} className={styles.productItem}>
                            <h1>{item2.title}</h1>
                            <h2>{item2.price}</h2>
                            <ul className={styles.listTags}>
                            {item2.desc.map((des, index) => (
                                <li key={index}>{des}</li>
                            ))}
                            </ul>
                            <NavLink to={`/produtos/${item2.id}`} style={{
                                width:'100%',
                                textDecoration:'none',
                            }}>
                                <button className={styles.btnSeeMore}>Ver mais</button>
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.divNotices}>
                <h1>Avisos</h1>
                {jsonNotice.notice.map((item) => (
                    <div key={item.id} className={styles.noticeItem}>
                        <h1>{item.title}</h1>
                        <h2>{item.date}</h2>
                        <p>{item.desc}</p>
                        <ul className={styles.listTags}>
                            {item.tags.map((tag, index) => (
                                <li key={index}>{tag}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
        
    )
}

export default Home