import styles from './Product.module.css'
import { useParams, NavLink } from 'react-router-dom'
import jsonProducts from '../../products.json'

//icon
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaCartShopping } from "react-icons/fa6";
import { IoMdCheckmark } from "react-icons/io";

//img
import icon from '../../assets/img_buy.png'

const Product = () => {
    const { productID } = useParams()

    const product = jsonProducts.products.find(product => product.id === parseInt(productID))

    if (!product) {
        return <div>Produto não encontrado.</div>;
    }

    return (
        <div className={styles.product}>
            <div className={styles.headerContainerProducts}>
                <div className={styles.headerDivTextContainerProducts}>
                    <h1>{product.title}</h1>
                    <h2>Preço: {product.price}</h2>
                    <p>{product.body}</p>
                    <NavLink style={{
                        width:'80%',
                        textDecoration:'none',
                        marginBottom:'1%',
                    }} to={"/"}>
                        <button><FaArrowLeftLong style={{
                            marginRight:'2%',
                        }} />Voltar</button>
                    </NavLink>
                    <NavLink style={{
                        width:'80%',
                        textDecoration:'none',
                    }} to={"/comprar"}>
                        <button><FaCartShopping style={{
                            marginRight:'2%',
                        }} />Comprar</button>
                    </NavLink>
                </div>
                <img src={icon}/>
            </div>
            <div className={styles.infoContainerProducts}>
                <ul className={styles.listTags}>
                    {product.desc.map((description, index) => (
                        <li key={index}><IoMdCheckmark style={{
                            color:'#e3e3e3',
                            fontSize:'1.5em',
                            marginRight:'2%',
                        }} />{description}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Product
