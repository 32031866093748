import styles from './NavBar.module.css'

import { NavLink } from "react-router-dom"

import { useAuthentication } from "../../hooks/useAuthentication"

import { useAuthValue } from "../../context/AuthContext"

//img
import icon from '../../assets/icone_cycle.png'

const NavBar = () => {
  const { user } = useAuthValue()
  const { logout } = useAuthentication()

  return (
      <nav>
          <img src={icon} alt='Nossa logo' />
          <div className={styles.divContainerItens}>
            <div className={`${styles.btnGroup} ${"btn-group"}`}>
              <button className={`${styles.btnMenu} ${"btn btn-transparent dropdown-toggle"}`} data-bs-toggle="dropdown" aria-expanded="false">
                Opções
              </button>
              <ul className={`${styles.divContainerMenu} ${"dropdown-menu dropdown-menu-lg-end"}`}>
                <li className={styles.listItensContainerMenu}><a className={`${styles.itemsContainerMenu} ${"dropdown-item"}`} href="/">Início</a></li>
                <li className={styles.listItensContainerMenu}><a className={`${styles.itemsContainerMenu} ${"dropdown-item"}`} href="/comprar">Compre agora</a></li>
                <li className={styles.listItensContainerMenu}><a className={`${styles.itemsContainerMenu} ${"dropdown-item"}`} href="/perguntas">FAQ</a></li>
              </ul>
            </div>
            {user && (
              <button onClick={logout} className={styles.btnLogout}>
                Sair
              </button>
            )}
          </div>
      </nav>
  )
}

export default NavBar;
