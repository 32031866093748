import styles from './Login.module.css'

import { useEffect, useState } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const { login, error: authError, loading } = useAuthentication();

    const handleSubmit = async (e) =>{
        e.preventDefault()

        setError("")

        const user = {
            email,
            password,
        }

        const res = await login(user)
        console.log(res)
    }

    useEffect(() =>{
        if(authError){
            setError(authError)
        }
    },[authError])

    return (
        <div className={styles.login}>
            <h1>Entrar</h1>
            <p>Entra para comprar conosco</p>
            <form onSubmit={handleSubmit}>
                <input placeholder='Seu e-mail' type="email" name='email'  onChange={(e) => setEmail(e.target.value)} value={email}/>
                <input placeholder='Senha:' type="password"  name='password' onChange={(e) => setPassword(e.target.value)} value={password}/>
                {!loading && <button style={{marginTop:"0.4%", width:"100%"}} >Entrar</button>}
                {loading && (
                    <button type="button" disabled>
                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status">Loading...</span>
                    </button>
                )}
                {error && <p className="error">{error}</p>}
                <p style={{
                    marginTop:'5%',
                    color:'#888888',
                }}>Não tem uma conta? <a style={{
                    textDecoration:'none',
                }} href='/registro'>Crie uma</a></p>
            </form>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
    )
}

export default Login
